// https://davestewart.github.io/vuex-pathify/#/setup/config
import Vue from 'vue';
import pathify from 'vuex-pathify'

// options
pathify.options.mapping = 'simple'
pathify.options.strict = true

Vue.use(pathify);

export default pathify
import { post, get, put } from "@/utils/api";
import {
  USER_LOGIN,
  USER_GET,
  USER_PUT,
  USER_REGISTRATION,
  USERS_GET,
  USER_PASSWORD_CHANGE
} from "@/store/actions/users";
import store from "@/store";

const state = {
  profile: null,
  startExam: null,
};

const getters = {
  profile: (state) => state.profile,
  isProfileLoaded: (state) => state.profile && !!state.profile.id,
};

const actions = {
  [USER_LOGIN]: async (_, [data]) => {
    let resp = await post("/api/login/", data);
    if (resp.success) {
      localStorage.setItem("token", resp.result.token);
    }
    return resp;
  },

  [USER_GET]: async (_) => {
    return await get("/api/get-user/");
  },

  [USERS_GET]: async (_, [f]) => {
    if (typeof f != "undefined") {
      return await get("/api/user/" + f);
    }
    return await get("/api/user/");
  },

  [USER_PUT]: async (_, [data]) => {
    return await put("/api/user/" + state.profile.id + "/", data);
  },

  [USER_REGISTRATION]: async (_, [data]) => {
    return await post("/api/registration/", data);
  },
  [USER_PASSWORD_CHANGE]: async (_, [data]) => {
    return await post("/api/password_change/", data);
  },
};

const mutations = {
  profile(state, profile) {
    state.profile = profile;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

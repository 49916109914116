import Vue from "vue";
import VueRouter from "vue-router";
import IsAuthenticated from "@/utils/validators";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/notifications",
    name: "Notifications",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "@/views/Notifications.vue"
      ),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: () =>
      import(/* webpackChunkName: "tasks" */ "@/views/Calendar/Tasks.vue"),
  },
  {
    path: "/employees",
    name: "Employee",
    component: () =>
      import(
        /* webpackChunkName: "employee" */ "@/views/Calendar/Employee.vue"
      ),
  },
  {
    path: "/",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/views/Calendar/Dashboard.vue"
      ),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "setting" */ "@/views/settings.vue"),
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: () =>
      import(
        /* webpackChunkName: "schedule" */ "@/views/Schedule/Schedule.vue"
      ),
  },
  {
    path: "/teachers",
    name: "Teachers",
    component: () =>
      import(/* webpackChunkName: "teacher" */ "@/views/Schedule/Teachers.vue"),
  },
  {
    path: "/groups",
    name: "Groups",
    component: () =>
      import(/* webpackChunkName: "group" */ "@/views/Schedule/Groups.vue"),
  },
  {
    path: "/groups/:gid",
    name: "GroupDetails",
    component: () =>
      import(
        /* webpackChunkName: "group-details" */ "@/views/Schedule/GroupDetails.vue"
      ),
  },
  {
    path: "/teachers/:id/syllabus",
    name: "Syllabus",
    component: () =>
      import(
        /* webpackChunkName: "syllabus" */ "@/views/Schedule/Syllabus.vue"
      ),
  },
  {
    path: "/teachers/:id/syllabus/:sid",
    name: "SyllabusDetails",
    component: () =>
      import(
        /* webpackChunkName: "syllabus-details" */ "@/views/Schedule/SyllabusDetails.vue"
      ),
  },
  {
    path: "/students",
    name: "Students",
    component: () =>
      import(
        /* webpackChunkName: "students" */ "@/views/Schedule/Students.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let a = await IsAuthenticated();
  let type = a.type;
  if (
    to.name === "Dashboard" &&
    (type === "teacher" || type === "training_officer" || type === "student")
  )
    next({ name: "Schedule" });
  if (
    to.name === "Tasks" &&
    (type === "teacher" || type === "training_officer" || type === "student")
  )
    next({ name: "Schedule" });
  if (
    to.name === "Employee" &&
    (type === "teacher" || type === "training_officer" || type === "student")
  )
    next({ name: "Schedule" });
  if (
    to.name === "Schedule" &&
    (type === "worker" || type === "hod" || type === "depdir")
  )
    next({ name: "Dashboard" });
  if (
    to.name === "Teachers" &&
    (type === "worker" || type === "hod" || type === "depdir")
  )
    next({ name: "Dashboard" });
  if (
    to.name === "Groups" &&
    (type === "worker" || type === "hod" || type === "depdir")
  )
    next({ name: "Dashboard" });
  if (
    to.name === "GroupDetails" &&
    (type === "worker" || type === "hod" || type === "depdir")
  )
    next({ name: "Dashboard" });
  if (
    to.name === "Syllabus" &&
    (type === "worker" || type === "hod" || type === "depdir")
  )
    next({ name: "Dashboard" });
  if (
    to.name === "SyllabusDetails" &&
    (type === "worker" || type === "hod" || type === "depdir")
  )
    next({ name: "Dashboard" });
  if (
    to.name === "Students" &&
    (type === "worker" || type === "hod" || type === "depdir")
  )
    next({ name: "Dashboard" });
  if (to.name === "Teachers" && (type === "student" || type === "teacher"))
    next({ name: "Schedule" });
  if (
    to.name === "Students" &&
    (type === "worker" || type === "hod" || type === "depdir")
  )
    next({ name: "Dashboard" });
  if (to.name === "Students" && (type === "student" || type === "teacher"))
    next({ name: "Schedule" });
  if (to.name !== "Login" && !a.is_authenticated) next({ name: "Login" });
  else next();
});

export default router;

import {
  TASKS_GET,
  INDIVIDUAL_TASKS_GET,
  TASK_DELETE,
  TASK_EDIT,
  TASK_GET,
  TASK_POST,
  INDIVIDUAL_TASKS_POST,
  INDIVIDUAL_TASKS_UNTIL_POST,
  INDIVIDUAL_TASK_PUT,
  TASK_PATCH,
} from "../actions/task";
import { get, post, put, del, patch } from "@/utils/api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [TASKS_GET]: async (_, [data]) => {
    return await get("/api/calendar/task/");
  },

  [INDIVIDUAL_TASKS_GET]: async (_, [data]) => {
    let resp;
    if (typeof data != "undefined") {
      resp = await get("/api/calendar/individual_tasks/" + data);
    } else {
      resp = await get("/api/calendar/individual_tasks/");
    }
    return resp;
  },

  [TASK_GET]: async (_, [id]) => {
    return await get("/api/calendar/task/" + id.toString() + "/");
  },

  [TASK_POST]: async (_, [data]) => {
    return await post("/api/calendar/task/", data);
  },

  [INDIVIDUAL_TASKS_POST]: async (_, [data]) => {
    return await post("/api/calendar/individual_tasks/", data);
  },

  [INDIVIDUAL_TASK_PUT]: async (_, [data]) => {
    return await put(
      "/api/calendar/individual_tasks/" + data.id.toString() + "/",
      data
    );
  },

  [INDIVIDUAL_TASKS_UNTIL_POST]: async (_, [data]) => {
    return await post(
      "/api/calendar/more/individual/task/create/daily/until",
      data
    );
  },

  [TASK_EDIT]: async (_, [data]) => {
    return await put("/api/calendar/task/" + data.id.toString() + "/", data);
  },

  [TASK_PATCH]: async (_, [data]) => {
    return await patch("/api/calendar/task/" + data.id.toString() + "/", data);
  },

  [TASK_DELETE]: async (_, [id]) => {
    return await del("/api/calendar/task/" + id.toString() + "/");
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

import {
    STUDENT_GET, STUDENT_PUT, STUDENT_POST
  } from "../actions/student";
  import { get, put, post } from "@/utils/api";
  
  const state = {};
  
  const getters = {};
  
  const mutations = {};
  
  const actions = {
    [STUDENT_GET]: async (_, [data]) => {
      if(typeof(data) != 'undefined'){
        return await get("/api/schedule/student/" + data.toString());
      }
      return await get("/api/schedule/student/");
    },

    [STUDENT_PUT]: async (_, [data]) => {
      return await put("/api/schedule/student/" + data.id.toString() + '/', data);
    },

    [STUDENT_POST]: async (_, [data]) => {
      return await post("/api/schedule/student/", data);
    },
  };
  
  export default {
    state,
    mutations,
    actions,
    getters,
  };
  
import {
  SYLLABUS_GET,
  SYLLABUS_GET_ID,
  SYLLABUS_PUT,
  SYLLABUS_POST,
} from "../actions/syllabus";
import { get, put, post } from "@/utils/api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [SYLLABUS_GET]: async (_, [id]) => {
    return await get("/api/schedule/syllabus/?teacher=" + id.toString());
  },

  [SYLLABUS_GET_ID]: async (_, [data]) => {
    return await get("/api/schedule/syllabus/?number=" + data.number.toString() + '&teacher=' + data.teacher.toString());
  },

  [SYLLABUS_PUT]: async (_, [data]) => {
    return await put(
      "/api/schedule/syllabus/" + data.id.toString() + "/",
      data
    );
  },

  [SYLLABUS_POST]: async (_, [data]) => {
    return await post("/api/schedule/syllabus/", data);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

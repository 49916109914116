import { DEPARTMENTS_GET, DEPARTMENT_GET, DEPARTMENT_DELETE, DEPARTMENT_POST, DEPARTMENT_EDIT, DEPARTMENTS_PATCH } from "../actions/department";
import { get, post, put, del, patch } from "@/utils/api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [DEPARTMENTS_GET]: async (_, [f]) => {
    if (typeof f != 'undefined'){
      return await get("/api/calendar/department/" + f);
    }
    return await get("/api/calendar/department/");
  },

  [DEPARTMENT_GET]: async (_, [id]) => {
    return await get("/api/calendar/department/" + id.toString() + '/');
  },

  [DEPARTMENT_POST]: async (_, [data]) => {
    return await post("/api/calendar/department/", data);
  },

  [DEPARTMENTS_PATCH]: async (_, [data]) => {
    return await patch("/api/calendar/department/" + data.id.toString() + '/', data);
  },

  [DEPARTMENT_EDIT]: async (_, [data]) => {
    return await put("/api/calendar/department/" + data.id.toString() + '/', data);
  },

  [DEPARTMENT_DELETE]: async (_, [id]) => {
    return await del("/api/calendar/department/" + id.toString() + '/');
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

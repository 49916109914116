
export default {
  data() {
    return {
      x_loading: false,
      errors: [],
    };
  },
  methods: {
    $breadcrumb(list) {
      list.unshift({
        text: this.$t("nav.home"),
        href: "/",
      });

      this.$store.commit("breadcrumb", list);
    },
    async $upload(input) {
      return new Promise((resolve) => {
        if (!input.files || !input.files[0]) {
          return;
        }

        let reader = new FileReader();
        reader.onload = (ee) => {
          resolve({ file: input.files[0], preview: ee.target.result });
        };

        reader.readAsDataURL(input.files[0]);
      });
    },
    async $dispatch(action, ...params) {
      this.x_loading = true;
      this.errors = [];

      let resp = await this.$store.dispatch(action, [...params]);

      if (resp.success){
        this.x_loading = false;
      } else if (resp.error) {
        if (resp.message) {
          alert(resp.message);
          this.x_loading = true;
        }
      } else if (resp.fail) {
        this.errors = resp.data;
        this.x_loading = true;
      } else if (resp.status === '401'){
        this.x_loading = true;
      }
      return resp;
    },
  },
};

import {
    TEACHER_GET, TEACHER_POST
  } from "../actions/teacher";
  import { get, post } from "@/utils/api";
  
  const state = {};
  
  const getters = {};
  
  const mutations = {};
  
  const actions = {
    [TEACHER_GET]: async (_, [data]) => {
      if(typeof(data) != 'undefined'){
        return await get("/api/schedule/teacher/" + data.toString() + '/');
      }
      return await get("/api/schedule/teacher/");
    },

    [TEACHER_POST]: async (_, [data]) => {
      return await post("/api/schedule/teacher/", data);
    },
  };
  
  export default {
    state,
    mutations,
    actions,
    getters,
  };
  
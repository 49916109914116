<template>
  <div id="app">
    <v-app>
      <default-drawer />

      <!-- Sizes your content based upon application components -->
      <v-main>
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          <!-- If using vue-router -->
          <transition name="page" mode="out-in">
            <router-view></router-view>
          </transition>
        </v-container>
        <BottomNav />
      </v-main>

      <v-footer app> </v-footer>
    </v-app>
  </div>
</template>

<script>
import store from "./store";
import axios from "axios";
export default {
  name: "App",

  components: {
    // Sidebar,
    DefaultDrawer: () =>
      import(
        /* webpackChunkName: "default-drawer" */
        "@/components/Drawer"
      ),
    BottomNav: () =>
      import(
        /* webpackChunkName: "default-drawer" */
        "@/components/BottomNav"
      ),
  },

  // Test dark theme
  // created(){
  //   this.$vuetify.theme.dark = true
  // }
  created() {
    let token = localStorage.getItem("token");
    if (token) {
      axios
        .get("/api/get-user/", {
          headers: {
            Authorization: "Token " + token,
          },
        })
        .then((r) => {
          store.commit("profile", r.data.result);
          axios
            .get(
              `/api/calendar/notifications/?to_users=${store.getters.profile.id}&seen=false`,
              {
                headers: {
                  Authorization: "Token " + token,
                },
              }
            )
            .then((r) => {
              store.commit("notifications", r.data.result);
            });
        });
    }
  },
};
</script>
<style lang='scss'>
@media only screen and (max-width: 667px) {
  .v-btn--fab.v-size--default {
    width: 32px !important;
    height: 32px !important;
  }
}

.v-skeleton-loader__text{
  height: 40px !important;
}

.v-calendar-daily__scroll-area {
  overflow-y: auto !important;
}
.v-slide-group__prev--disabled {
  display: none !important;
}
.v-application--wrap {
  min-height: 100vh !important;
}
.employee-data-row td {
  margin-top: 15px !important;
  border: none !important;
  font-size: 18px !important;
  padding: 8px !important;
}
.employee-data-row {
  border-radius: 12px;
  box-shadow: 0px 0px 10px #00000030 !important;
}
.employee-data-row td:first-child {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
.employee-data-row td:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.employee-data-row:hover {
  background-color: #eee !important;
}
.v-data-table > .v-data-table__wrapper > table {
  border-collapse: separate !important;
  border-spacing: 0 1em !important;
  padding: 5px !important;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 18px !important;
}
.v-event-timed-container {
  margin-right: 0 !important;
}
.v-item-group.v-bottom-navigation .v-btn {
  min-width: 0px !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave {
  opacity: 0;
}
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}
.page-enter,
.page-leave-to {
  opacity: 0;
  transform: scale(0);
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import * as modules from "./modules";
import pathify from '@/plugins/vuex-pathify'

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  state: {
    breadcrumb: null,
    profile: {}
  },
  mutations: {
    breadcrumb(state, list) {
      state.breadcrumb = [];
      state.breadcrumb.push(...list);
    },
  },
  modules,
  plugins: [
    pathify.plugin,
  ],
  // strict: debug
});

store.subscribe(mutation => {
  if (!mutation.type.startsWith('user/')) return

  store.dispatch('user/update', mutation)
})

store.dispatch('app/init')

export default store;

import { ATTENDANCE_GET, ATTENDANCE_POST } from "../actions/attendance";
import { get, post } from "@/utils/api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [ATTENDANCE_GET]: async (_, [f]) => {
    return await get("/api/schedule/attendance/" + f);
  },

  [ATTENDANCE_POST]: async (_, [data]) => {
    return await post("/api/schedule/attendance/", data);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

export const TASKS_GET = "TASKS_GET";
export const INDIVIDUAL_TASKS_GET = "INDIVIDUAL_TASKS_GET";
export const INDIVIDUAL_TASKS_POST = "INDIVIDUAL_TASKS_POST";
export const INDIVIDUAL_TASK_PUT = "INDIVIDUAL_TASK_PUT";
export const INDIVIDUAL_TASKS_UNTIL_POST = "INDIVIDUAL_TASKS_UNTIL_POST";
export const TASK_GET = "TASK_GET";
export const TASK_POST = "TASK_POST";
export const TASK_EDIT = "TASK_EDIT";
export const TASK_DELETE = "TASK_DELETE";
export const TASK_PATCH = "TASK_PATCH";

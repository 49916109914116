// Pathify
import { make } from 'vuex-pathify'

// Data
const items = [
  {
    title: 'Дашбоард',
    icon: 'mdi-view-dashboard',
    to: '/',
  },
  
  {
    title: 'Задания',
    icon: 'mdi-calendar-check',
    to: '/tasks/',
  },
  {
    title: 'Сотрудники',
    icon: 'mdi-account-group',
    to: '/employees/',
  },
  {
    title: 'Уведомления',
    icon: 'mdi-bell',
    to: '/notifications/',
    notifications: true
  },
  {
    title: 'Настройки',
    icon: 'mdi-cog',
    to: '/settings/',
  },
  {
    title: 'Расписание',
    icon: 'mdi-calendar-blank',
    to: '/schedule/',
  },
  {
    title: 'Группы',
    icon: 'mdi-account-group',
    to: '/groups/',
  },
  {
    title: 'Учителя',
    icon: 'mdi-human-male-board',
    to: '/teachers/',
  },
  {
    title: 'Ученики',
    icon: 'mdi-school',
    to: '/students/',
  },
]
const state = {
  drawer: true,
  drawerImage: true,
  mini: false,
  items: items,
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

import {
  COURSE_GET,
  LESSONS_GET,
  LESSONS_POST
} from "../actions/other";
import { get, post } from "@/utils/api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [COURSE_GET]: async (_, [data]) => {
    if(typeof(data) != 'undefined'){
      return await get("/api/schedule/direction/" + data);
    }
    return await get("/api/schedule/direction/");
  },
  
  [LESSONS_POST]: async (_, [data]) => {
    return await post("/api/schedule/more/lessons/create/", data);
  },

  [LESSONS_GET]: async (_, [data]) => {
    if (typeof(data) != 'undefined'){
      return await get("/api/schedule/lesson/" + data);
    }
    return await get("/api/schedule/lesson/");
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

import { GROUP_ADD, GROUP_GET } from "@/store/actions/group";
import { get, post } from "@/utils/api";

const state = {};

const mutations = {};

const getters = {};

const actions = {
  [GROUP_ADD]: async (_, [data]) => {
    return await post("/api/schedule/group/", data);
  },
  [GROUP_GET]: async (_, [f]) => {
    if (typeof(f) != "undefined") {
      if (typeof(f) == "number"){
        return await get("/api/schedule/group/" + f.toString() + '/');
      }
      return await get("/api/schedule/group/" + f);
    }
    return await get("/api/schedule/group/");
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespace: true,
};

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import helpers from "@/utils/helpers";
import validators from "@/utils/validators";
import Vuelidate from "vuelidate";
import truncate from "vue-truncate-collapsed";
import axios from "axios";


Vue.config.productionTip = false;
Vue.mixin(helpers, validators);
Vue.use(Vuelidate);
Vue.component("truncate", truncate);

let token = localStorage.getItem("token");
if (token) {
  axios
    .get("/api/get-user/", {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then((r) => {store.commit("profile", r.data.result);
    axios
    .get(`/api/calendar/notifications/?to_users=${store.getters.profile.id}&seen=false`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then((r) => {store.commit("notifications", r.data.result)});
  });
  
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

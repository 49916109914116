import { NOTIFICATION_GET, NOTIFICATION_POST, NOTIFICATION_DELETE, NOTIFICATION_PUT } from "../actions/notification";
import { get, post, del, put } from "@/utils/api";

const state = {
  notifications: []
};

const getters = {
  notifications: (state) => state.notifications,
};

const mutations = {
  notifications(state, notifications) {
    state.notifications = notifications;
  },
};

const actions = {
  [NOTIFICATION_GET]: async (_, [data]) => {
    if (typeof(data) != 'object'){
      return await get("/api/calendar/notifications/?to_users=" + data.toString());
    }else{
      return await get("/api/calendar/notifications/?to_users=" + data.id.toString() + '&' + data.filter);
    }
  },

  [NOTIFICATION_POST]: async (_, [data]) => {
    return await post("/api/calendar/notifications/", data);
  },

  [NOTIFICATION_DELETE]: async (_, [id]) => {
    return await del("/api/calendar/notifications/" + id.toString() + '/');
  },

  [NOTIFICATION_PUT]: async (_, [data]) => {
    return await put("/api/calendar/notifications/" + data.id.toString() + '/', data);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

import { helpers } from "vuelidate/lib/validators";
import axios from "axios";

// const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

async function IsAuthenticated() {
  let resp = await axios.get("/api/is-authenticated/").then((r) => {
    if (r.data.result.is_authenticated) {
      return { is_authenticated: true, type: r.data.result.type };
    } else {
      localStorage.removeItem("token");
      return { is_authenticated: false };
    }
  });
  return resp;
}

function checkBirthday(value) {
  var date = new Date();

  console.log(value);

  var t = {
    year: date.getFullYear(),
    month: date.getMonth(),
    day: date.getDate(),
  };
  var d = {
    year: value.split("-")[0],
    month: value.split("-")[1],
    day: value.split("-")[2],
  };
  var year = parseInt(t.year) - parseInt(d.year);
  var month, day;
  month = parseInt(t.month) >= parseInt(d.month);
  day = parseInt(t.day) >= parseInt(d.day);
  if (!month) {
    if (parseInt(t.month) == parseInt(d.month)) {
      if (!day) {
        year--;
      }
    }
  }

  if (Number(year) < 16) {
    console.log(year);
    return false;
  } else if (Number(year) > 60) {
    return false;
  } else {
    return true;
  }
}

async function check_email_func(email) {
  return await (await axios.post("/api/check_email/", { email: email })).data
    .status;
}

async function check_phone_func(phone) {
  if (phone.length != 12) {
    return false;
  } else {
    let resp = await axios.post("/api/check_phone/", { phone: phone });
    console.log(resp);
    return await axios.post("/api/check_phone/", { phone: phone }).data.result
      .phone;
  }
}

export async function phone(value) {
  if (!helpers.req(value)) {
    return true;
  }

  try {
    const validate = await check_phone_func(value);
    if (!validate) {
      throw "Error";
    }
  } catch (e) {
    return false;
  }

  return true;
}

export function birthday(value) {
  if (!helpers.req(value)) {
    return true;
  }

  try {
    const validate = checkBirthday(value);
    if (!validate) {
      throw "Error";
    }
  } catch (e) {
    return false;
  }

  return true;
}

export default IsAuthenticated;
export async function isFromHR(id) {
  let department = await axios.get("/api/calendar/department/?workers=" + id);
  if (department.result.length > 0) {
    if (department.result[0].name == "Отдел кадров") {
      return true;
    }
  } else {
    let resp = await axios.get("/api/calendar/department/?hod=" + id);
    if (resp.result[0].name == "Отдел кадров") {
      return true;
    }
  }
  return false;
}

export async function Check_email(value) {
  if (!helpers.req(value)) {
    return true;
  }

  try {
    const validate = await check_email_func(value);
    if (validate == "error") {
      throw "Error";
    }
  } catch (e) {
    return false;
  }

  return true;
}
